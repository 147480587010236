import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { AuthProvider } from "context/AuthContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ToastContainer } from "react-toastify";
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.3.0";
import "assets/demo/demo.css";
import "assets/demo/extrafonts.css";
import "assets/scss/form-style/CommonFormStyle.scss";
import "assets/scss/common-style/common.scss";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { ErrorBoundary } from "react-error-boundary"; // Import ErrorBoundary
import ErrorBoundaryFallback from "./ErrorBoundaryFallback"; // Import fallback component

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient();
root.render(
  <ErrorBoundary
    FallbackComponent={ErrorBoundaryFallback}
    onReset={() => window.location.reload()} // Reload the app on reset
  >
  <BrowserRouter>
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <Elements stripe={stripePromise}>
          <GoogleOAuthProvider
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          >
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              closeOnClick
              theme="colored"
            />
            <App />
          </GoogleOAuthProvider>
        </Elements>
      </QueryClientProvider>
    </AuthProvider>
  </BrowserRouter>
  </ErrorBoundary>
);
