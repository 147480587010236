// ErrorBoundaryFallback.js
import React from "react";

const ErrorBoundaryFallback = ({ error, resetErrorBoundary }) => (
  <div style={{ padding: "20px", textAlign: "center" }}>
    <h3>Update Required to Continue.</h3>
    {/* <p>{error?.message || "An unexpected error occurred."}</p> */}
    <p>It looks like your browser is outdated and doesn’t support the features required to run this application. Please update your browser to the latest version to access all features, including notifications.</p>
    {/* <button onClick={resetErrorBoundary}>Try Again</button> */}
  </div>
);

export default ErrorBoundaryFallback;
